import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../common/variable';
import { Observable } from 'rxjs';
import { IFilizDevicePostOptions, IFilizDeviceBatchPostOptions, IFilizDeviceLocationUpdateOptions } from '../common/filiz/FilizResponse';
import { IGetFilizGroupOptions, FilizDashboardItem } from '../common/filiz/FilizModel';
import { ApiResponse } from '../common/model/ApiResponse';

@Injectable({
    providedIn: 'root'
})
export class FeromonService {
    categoryList: Array<any> = [];
    constructor(public http: HttpClient) { }

    getDeviceById(id: number): Observable<any> {
        return this.http.get(AppConstants.Services.ApiService + `/api/feromon/device/${id}`);
    }
    updateDevice(data: IFilizDevicePostOptions): Observable<any> {
        return this.http.post(AppConstants.Services.DoktarV2Service + '/api/device/feromon/devices/update', data);
    }
    getDevicesList(id = 0): Observable<any> {
        return this.http.get(AppConstants.Services.DoktarV2Service + `/api/device/feromon/device/list?id=${id}`);
    }
    getDeviceStatusById(id: number): Observable<ApiResponse<any>> {
        return this.http.get<ApiResponse<any>>(AppConstants.Services.ApiService + `/api/get/feromon/device/status?deviceId=${id}`);
    }
    updateDeviceStatus(data: any): Observable<any> {
        return this.http.post(AppConstants.Services.ApiService + `/api/set/feromon/device/response/value`, data);
    }
    updateFilizLocation(deviceId: number, data: IFilizDeviceLocationUpdateOptions): Observable<any> {
        return this.http.post(AppConstants.Services.ApiService + `/api/feromon/device/location/update?deviceId=${deviceId}`, data);
    }
    getUserRoleId(phoneNumber: string) {
        return this.http.post(AppConstants.Services.ApiService + `/api/get/userroleid/${phoneNumber}/by/telno/`, {});
    }
    setLog(model: any) {
        return this.http.post(AppConstants.Services.ApiService + `/api/set/sensor/action`, model);
    }
    getDeviceBatchList(pageCount = 1, takeCount = 15): Observable<any> {
        return this.http.get(AppConstants.Services.DoktarV2Service + `/api/device/feromon/batch/list?pageCount=${pageCount}&takeCount=${takeCount}`);
    }
    getLatestDevice(): Observable<any> {
        return this.http.get(AppConstants.Services.ApiService + `/api/get/latest/feromon/device/info`);
    }
    postFeromonDeviceBatch(data: any): Observable<any> {
        return this.http.post(AppConstants.Services.DoktarV2Service + '/api/device/feromon/batch/update', data);
    }
    getFeromonDeviceAllLogs(feromonId) {
        return this.http.get(AppConstants.Services.DoktarV2Service + `/api/device/trap/sensor/value/list?id=${feromonId}`);
    }
    deleteFeromon(deviceId: number): Observable<ApiResponse<any>> {
        return this.http.get<ApiResponse<any>>(AppConstants.Services.ApiService + '/api/feromon/remove/' + deviceId);
    }
    getDashboardResponse(): Observable<ApiResponse<FilizDashboardItem[]>> {
        return this.http.get<ApiResponse<FilizDashboardItem[]>>(AppConstants.Services.ApiService + '/api/get/dashboard/feromon');
    }

    getChangesLog(deviceId: number): Observable<any> {
        return this.http.post<any>(AppConstants.Services.ApiService + '/api/get/feromon/component/changes/log', { DeviceId: deviceId });
    }
    saveInsect(deviceId: any, insectTypeId: number): Observable<any> {
        return this.http.post<any>(AppConstants.Services.ApiService + "/api/change/feromon/insect/type?deviceId=" + deviceId + "&insectTypeId=" + insectTypeId, {});
    }
    getSvgPoints(requestJson: string): Observable<any> {
        return this.http.get<any>(requestJson);
    }

    getCategories(): Observable<any> {
        return this.http.get<any>("https://api.doktarim.com/v2/api/device/trap/getallpests");
    }
    getTimezone(): Observable<any> {
        return this.http.get<any>("../assets/data/timezones.json");
    }
    saveDeviceFeature(data: any): Observable<any> {
        return this.http.post<any>(AppConstants.Services.ApiService + "/api/set/feromon/device/response/value", data);
    }

    changeFeromon(data: any): Observable<any> {
        return this.http.post<any>(AppConstants.Services.ApiService + "/api/save/feromon/change/component", data);
    }

    setSeasonDate(data: any): Observable<any> {
        return this.http.post<any>(AppConstants.Services.ApiService + "/api/update/device/season/date", data);
    }
    getBatchSpec(batchId: number): Observable<ApiResponse<any>> {
        return this.http.get<ApiResponse<any>>(AppConstants.Services.DoktarV2Service + `/api/device/feromon/batch/list?id=${batchId}`);
    }
    getInsectList(): Observable<any> {
        return this.http.get<any>("https://api.doktarim.com/v2/api/device/trap/getallpests");
    }
    saveInsectType(payload:any): Observable<any> {
        return this.http.post<any>(AppConstants.Services.ApiService + "", payload);
    }
    getImageJsons(id:number): Observable<any> {
        return this.http.get<any>("https://api.doktarim.com/v2/api/Makesense/GetLastRecords?imageId="+id);
    }
}
