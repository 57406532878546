

export const AppConstants = {
    Services: {
        AdminBaseUrl:"https://94.73.164.178:1014/",
        SubscriptionSrv: 'https://doktar.com:1022', // http://doktar.com:1028 https://api.doktarim.com
        DataService: 'https://doktar.com:1022', //'http://192.168.2.171//Doktar.Api',//'http://doktar.com:1022',//'http://localhost:65222'
        ApiService: 'https://api.doktarim.com',
        DoktarService: 'https://api-v3.doktarim.com',
        DoktarV2Service:'https://api.doktarim.com/v2',
        DoktarNewService:'https://api-v3.doktarim.com/v2',
        TempService: 'https://94.73.164.178:1014/FarmerProblems/GetProblemComingSources/?token=N2RZLEJTaN+WkYlDA9fJWQ==',
        AzureBlobService: 'https://doktarbase.blob.core.windows.net',
        DatasourceOptions: {
            pagerPageKey: 'pageCount',
            pagerLimitKey: 'takeCount',
            dataKey: 'Data',
            totalKey: 'Total'
        }
    },
    User: null,
    App: {
        // token: 'MJ+gntjIQ7fU7YIFKh8AnQ==',
        campaignId: '158',
        companyId: '143',
        fmsCampaignId: 142,
        roleId: '533',
        CurrentAccountList: [],
        BatchList: [],
        categoryList: [],
        CountryCodeList: [],
        GoogleMapsKey: '',
        DateFormat: {
            OnlyDate: 'YYYY-MM-DD',
            DateTime: 'YYYY-MM-DD HH:mm',
            OnlyHour:'HH:mm'
        },
        doktarId: 19
    },
    Storage: {
        User: 'user_storage_id',
        CurrentAccount: 'current_account'
    },
    TableSettings: {
        hideSubHeader: false,  // filter
        hideHeader: false,
        add: {
            addButtonContent: '<i class="nb-plus"></i>',
            createButtonContent: '<i class="nb-checkmark"></i>',
            cancelButtonContent: '<i class="nb-close"></i>',
        },
        edit: {
            editButtonContent: '<i class="nb-edit"></i>',
            saveButtonContent: '<i class="nb-checkmark"></i>',
            cancelButtonContent: '<i class="nb-close"></i>',
        },
        delete: {
            deleteButtonContent: '<i class="nb-trash"></i>',
            confirmDelete: true,
        },
        actions: {
            columnTitle: '',
            add: false,
            edit: false,
            delete: false,
            // custom: [{name: 'ourCustomAction'}],
        },
        columns: {

        },
        mode: 'external',
    },
    TableSettingsMulti: {
        hideSubHeader: false,  // filter
        hideHeader: false,
        add: {
            addButtonContent: '<i class="nb-plus"></i>',
            createButtonContent: '<i class="nb-checkmark"></i>',
            cancelButtonContent: '<i class="nb-close"></i>',
        },
        edit: {
            editButtonContent: '<i class="nb-edit"></i>',
            saveButtonContent: '<i class="nb-checkmark"></i>',
            cancelButtonContent: '<i class="nb-close"></i>',
        },
        delete: {
            deleteButtonContent: '<i class="nb-trash"></i>',
            confirmDelete: true,
        },
        actions: {
            columnTitle: '',
            add: false,
            edit: false,
            delete: false,
            // custom: [{name: 'ourCustomAction'}],
        },
        columns: {

        },
        mode: 'external',
        selectMode: 'multi',
    },
    OfflineData: {
        CropList: [],
        CropVarityList: []
    },
    Messages: {
        Success: "Success..."
    }
};
export const version = {
    'name': '1.2.184',
    'code': 884
};
