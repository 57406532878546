import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Observable } from 'rxjs/Observable';
import { AppConstants } from '../variable';

@Injectable({
  providedIn: 'root'
})
export class ConstantService {

  public constantList: Array<any> = [];

  constructor(private http: HttpClient, private dtToastrSvc: NbToastrService) { }

  getAllConstant(): Observable<Array<any>> {
    return new Observable(observer => {
      this.http.get<any>(AppConstants.Services.ApiService + "/api/constants/list/?groupId=0").subscribe(res => {
        if (res.IsSuccess) {
          this.constantList = res.Data;
          observer.next(res);
        } else {
          this.dtToastrSvc.show((res.Message ? res.Message : 'Unexpected error'), '', { status: 'danger' });
        }
      }, (error) => {
        this.dtToastrSvc.show((error.message ? error.message : 'Unexpected error'), '', { status: 'danger' });
      });
    });
  }

  getGroupConstant(id): Observable<Array<any>> {
    return new Observable(observer => {
      this.http.get<any>(AppConstants.Services.ApiService + "/api/constants/list/?groupId=" + id).subscribe(res => {
        if (res.IsSuccess) {
          observer.next(res.Data);
        } else {
          this.dtToastrSvc.show((res.Message ? res.Message : 'Unexpected error'), '', { status: 'danger' });
        }
      }, (error) => {
        this.dtToastrSvc.show((error.message ? error.message : 'Unexpected error'), '', { status: 'danger' });
      });
    });
  }

  getConstantByGroupId(id: number): Array<any> {
    var filtered = this.constantList.filter(e => e.GroupId == id) as Array<any>;
    return filtered;
  }

  addConstantValue(constant) {
    return new Observable(observer => {
      this.http.post<any>(AppConstants.Services.ApiService + "/api/save/tariff", constant).subscribe(res => {
        if (res.IsSuccess) {
          this.getAllConstant().subscribe(e => { });
          this.getGroupConstant(constant.GroupId).subscribe(e => {
            observer.next(e);
          });
        }
      });
    });
  }

  getGroupConstantV2(id): Observable<Array<any>> {
    return new Observable(observer => {
      this.http.get<any>(AppConstants.Services.DoktarV2Service + "/api/constants/list/by/groupId?groupId=" + id).subscribe(res => {
        if (res.IsSuccess) {
          observer.next(res.Data);
        } else {
          this.dtToastrSvc.show((res.Message ? res.Message : 'Unexpected error'), '', { status: 'danger' });
        }
      }, (error) => {
        this.dtToastrSvc.show((error.message ? error.message : 'Unexpected error'), '', { status: 'danger' });
      });
    });
  }
}
